.rounded-4 { border-radius: .5rem !important; }
.rounded-5 { border-radius: 1rem !important; }

.text-shadow-1 { text-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25); }
.text-shadow-2 { text-shadow: 0 .25rem .5rem rgba(0, 0, 0, .25); }
.text-shadow-3 { text-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .25); }

.card-cover {
  background-repeat: no-repeat;
  background-position: center top, center bottom;
  background-size: 80%,100%;
  width: 296px;
  height: 480px;
}

.addEffect:hover{
    filter: brightness(0.5);
}
